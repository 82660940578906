import AOS from 'aos/dist/aos';
import MaskIn from './MaskIn';

export default function () {
  setTimeout(() => {
    document.addEventListener('aos:in:mask', ({ detail }) => {
      if (detail.detail) { // IE & Edge fix
        return new MaskIn(detail.detail);
      }
      return new MaskIn(detail);
    });

    AOS.init({
      duration: 1000,
      offset: 0,
      once: true,
    });
  });
}
