import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import EventEmitter from 'events';

gsap.registerPlugin(CSSPlugin);

export default class MaskIn extends EventEmitter {
  constructor(element) {
    super();
    this.animationIn = null;
    this.animationOut = null;
    this.element = element;
  }

  startInAnimation(onComplete) {
    this.animationIn = gsap.to(
      this.element,
      {
        scaleX: 0,
        scaleY: 1,
        ease: 'Quart.easeInOut',
        duration: 0.8,
      },
    ).then(() => {
      this.emit('finished-out');
      if (onComplete) {
        onComplete();
      }
    });
  }

  startOutAnimation(onComplete) {
    this.animationOut = gsap.to(
      this.element,
      {
        scaleX: 1,
        scaleY: 1,
        ease: 'Quart.easeInOut',
        duration: 0.5,
      },
    ).then(() => {
      this.emit('finished-in');
      if (onComplete) {
        onComplete();
      }
    });
  }

  cancel() {
    if (this.animationIn) {
      this.animationIn.kill();
    }
    if (this.animationOut) {
      this.animationOut.kill();
    }
  }
}
