import FadeIn from '../animations/FadeIn';
import FadeOut from '../animations/FadeOut';

const EventEmitter = require('events');

export default class ModalAnimation extends EventEmitter {
  constructor(id) {
    super();
    this.modal = document.getElementById(id);
    this.fadeInAnimation = new FadeIn(this.modal);
    this.fadeOutAnimation = new FadeOut(this.modal);

    this.fadeInAnimation.on('finished', () => {
      this.emit('before-show-finished');
    });

    this.fadeOutAnimation.on('finished', () => {
      this.emit('before-hide-finished');
    });
  }

  beforeShow() {
    this.fadeInAnimation.start();
  }

  cancelBeforeShow() {
    this.fadeInAnimation.cancel();
  }

  afterShow() {
    this.emit('after-show-finished');
  }

  cancelAfterShow() {

  }

  beforeHide() {
    this.fadeOutAnimation.start();
  }

  cancelBeforeHide() {
    this.fadeOutAnimation.cancel();
  }

  afterHide() {
    this.emit('after-hide-finished');
  }

  cancelAfterHide() {

  }
}
