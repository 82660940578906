import Headroom from 'headroom.js';

export default function () {
  if (Headroom.cutsTheMustard) {
    const header = document.querySelector('.page-header');
    const headroom = new Headroom(header, {
      offset: 145,
      tolerance: 10,
    });

    headroom.init();
  }
}
