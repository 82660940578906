import { matchMedium } from '../../constants/defaults';

export default function () {
  matchMedium.addListener((mediaQuery) => {
    const { activeModal } = window.modalManager;

    if (mediaQuery.matches && activeModal && activeModal.id === 'modal-menu') {
      activeModal.hide();
    }
  });
}
