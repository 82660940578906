import 'classlist-polyfill';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/blur-up/ls.blur-up';

import ModalManager from './components/modals/ModalManager';
import navigation from './components/layout/navigation';
import headroom from './components/layout/headroom';
import responsiveVideos from './components/layout/responsiveVideos';
import scrollAnimations from './components/animations/scrollAnimations';
import PageTransition from './components/animations/PageTransition';
// import cookieNotification from './components/cookies/cookieNotification';

import Contact from './pages/Contact';

require('./utils/nativeConsole');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

const pageFunctions = {
  functions: [
    responsiveVideos,
    scrollAnimations,
    // cookieNotification,
    // gaDevelopment // Enable this if you want to test ga calls in development
  ],
  pages: {
    Contact,
  },
};

// Layout setup
window.modalManager = new ModalManager();
navigation();
headroom();
window.pageTransition = new PageTransition(pageFunctions);
