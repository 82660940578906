import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import EventEmitter from 'events';

gsap.registerPlugin(CSSPlugin);

export default class MaskIn extends EventEmitter {
  constructor(element, animationDuration) {
    super();
    this.animationWhite = null;
    this.animationAccent = null;
    this.animationImg = null;
    this.element = element;
    this.overlayWhite = element.querySelector('.overlay--white');
    this.overlayAccent = element.querySelector('.overlay--accent');
    this.img = element.querySelector('img');
    this.isAnimationUp = element.classList.contains('mask-animation--up');
    this.transformOrigin = this.isAnimationUp ? 'bottom 0' : 'left 50% 0px';
    this.defaultOptions = {
      scaleX: this.isAnimationUp ? 1 : 0,
      scaleY: this.isAnimationUp ? 0 : 1,
      ease: 'Quart.easeIn',
      duration: animationDuration || 0.7,
    };

    this.start();
  }

  start() {
    if (this.img) {
      this.animationImg = gsap.to(this.img, {
        scale: 1,
        ease: 'Quart.easeOut',
        duration: 4,
      });
    }
    if (this.overlayWhite) {
      this.animationWhite = gsap.to(this.overlayWhite, this.defaultOptions);
    }
    if (this.overlayAccent) {
      this.animationAccent = gsap.fromTo(
        this.overlayAccent,
        {
          scaleX: 1,
          scaleY: 1,
        },
        {
          ...this.defaultOptions,
          delay: '0.3',
          onComplete: () => {
            if (this.overlayWhite) {
              this.overlayWhite.style.transformOrigin = this.transformOrigin;
            }
          },
        },
      );
    }
  }

  cancel() {
    if (this.animationWhite) {
      this.animationWhite.kill();
    }
    if (this.animationAccent) {
      this.animationAccent.kill();
    }
    if (this.animationImg) {
      this.animationImg.kill();
    }
  }
}
