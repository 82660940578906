import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import EventEmitter from 'events';

gsap.registerPlugin(CSSPlugin);

export default class FadeOut extends EventEmitter {
  constructor(element, animationDuration) {
    super();
    this.element = element;
    this.duration = animationDuration || 0.3;
    this.animation = null;
  }

  start() {
    const to = {
      duration: this.duration,
      opacity: 0,
      ease: 'Power3.easeIn',
    };

    this.animation = gsap.to(this.element, to);
    this.animation.then(() => {
      this.emit('finished');
    });
  }

  cancel() {
    if (this.animation) {
      this.animation.kill();
    }
  }
}
