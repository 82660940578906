import barba from '@barba/core';
import PageMask from './PageMask';
import FadeIn from './FadeIn';
import FadeOut from './FadeOut';

export default class PageTransition {
  constructor(pageFunctions) {
    this.pageMask = new PageMask(document.querySelector('.page-transition'));
    this.container = document.querySelector('.main');
    this.containerFadeIn = new FadeIn(this.container);
    this.containerFadeOut = new FadeOut(this.container);
    this.pageFunctions = pageFunctions;

    this.initTransitions();
  }

  initTransitions() {
    const self = this;

    barba.init({
      logLevel: 'error',
      transitions: [{
        leave() {
          const { activeModal } = window.modalManager;

          if (activeModal) {
            activeModal.close();
          }

          return new Promise((resolve) => {
            self.containerFadeOut.start();
            self.pageMask.startOutAnimation(resolve);
          });
        },
        beforeEnter(data) {
          window.scrollTo({
            top: 0,
          });
          self.initPage(data);
        },
        enter() {
          self.pageMask.startInAnimation();
          self.containerFadeIn.start();
        },
        once(data) {
          self.initPage(data);

          return new Promise((resolve) => {
            self.pageMask.startInAnimation(resolve);
          });
        },
      }],
    });

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  initPage(data) {
    const { container, namespace, html } = data.next;

    this.setBodyClasses(container);
    this.initPageFunctions(namespace);
    this.setActiveNavItem(namespace);
    this.replaceHeadMeta(html);
  }

  setBodyClasses(container) {
    while (document.body.classList.length > 0) {
      document.body.classList.remove(document.body.classList.item(0));
    }

    if (container.dataset.barbaBodyClass) {
      document.body.classList.add(container.dataset.barbaBodyClass);
    }
  }

  initPageFunctions(namespace) {
    if (this.pageFunctions.functions) {
      this.pageFunctions.functions.forEach((pageFunction) => {
        pageFunction();
      });
    }

    if (namespace) {
      const pageClassName = namespace.charAt(0).toUpperCase() + namespace.slice(1);

      if (pageClassName !== '' && typeof this.pageFunctions.pages[pageClassName] === 'function') {
        new this.pageFunctions.pages[pageClassName](); // eslint-disable-line no-new
      }
    }
  }

  setActiveNavItem(namespace) {
    const navItemsActive = document.querySelectorAll(`[data-barba-active-namespace="${namespace}"]`);

    if (navItemsActive) {
      [...document.querySelectorAll('.js-navigation')].forEach((navigation) => {
        const prevNavItem = navigation.querySelector('li.active');

        if (prevNavItem) {
          prevNavItem.classList.remove('active');
        }
      });

      [...navItemsActive].forEach((navItem) => {
        navItem.classList.add('active');
      });
    }
  }

  replaceHeadMeta(html) {
    const { head } = document;
    const nextRawHead = html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
    const nextPageHead = document.createElement('head');
    const headTags = [
      'meta[name="keywords"]',
      'meta[name="description"]',
      'meta[property^="og"]',
      'meta[name^="twitter"]',
      'meta[itemprop]',
      'link[itemprop]',
      'link[rel="prev"]',
      'link[rel="next"]',
      'link[rel="canonical"]',
      'link[rel="alternate"]',
    ].join(',');

    nextPageHead.innerHTML = nextRawHead;

    [...head.querySelectorAll(headTags)].forEach((tag) => {
      head.removeChild(tag);
    });

    [...nextPageHead.querySelectorAll(headTags)].forEach((tag) => {
      head.appendChild(tag);
    });
  }
}
